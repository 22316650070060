import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="chi_siamo">
<h1>Antonio Gallenga, autore parmense tutto da scoprire</h1>
<ImgCentro nome="ritratto.PNG" alt="Ritratto" didascalia="Antonio Gallenga"/>
<p>Questo sito nasce in contemporanea alla pubblicazione inedita di tre racconti dell'autore tratti da <A href="/it/p/i_blackgown_papers">“I BLACKGOWN PAPERS”</A>, curata dalla Prof.ssa Gioia Angeletti.</p>
<br />
<p>Il progetto, voluto dal <A href="https://emiliaromagnaturismo.it/it/localita/bedonia">Comune di Bedonia (PR)</A>, è stato realizzato grazie al contributo dalla <A href="https://www.assemblea.emr.it/emilianoromagnolinelmondo">Consulta degli emiliano-romagnoli nel mondo</A>.</p>
<ImgLogo href="https://emiliaromagnaturismo.it/it/localita/bedonia" nome="bedonia.png" w ="7" alt="Bedonia"/>
<ImgLogo href="https://www.assemblea.emr.it/emilianoromagnolinelmondo" nome="consulta.png" w="20" alt="Consulta"/>
<p>Tutte le attività sono state coordinate dall'Associazione Appennino Ritrovato, che ha messo in campo le competenze di alcuni giovani locali motivati dalla volontà di ridare vita alle affascinanti vicende di Antonio Gallenga!</p>
<ImgLogo nome="associazione.png" alt="Appennino Ritrovato"/>
<p>Il progetto ha attivato e valorizzato partenariati tra varie associazioni del territorio:<br />
il Centro Studi Cardinal Casaroli APS, il Centro Studi Valceno Cardinal A. Sarmorè APS, l’Associazione Culturale Terra Mia e l’Università degli Studi di Parma.</p>
<p>Grazie alla realizzazione di queste attività abbiamo potuto riscoprire la vivace storia di Antonio Gallenga che, con il suo peregrinare in giro per il globo e la sua dotta istruzione, ha permesso la diffusione della cultura emiliano-romagnola già nel 1800.</p>
<h1>Chi siamo ...</h1>
<ChiSiamo>
	<ChiSono imgSrc="andre.png" nome="Andrea Ghirarduzzi">Docente di lingua inglese e di lingua italiana. Tra i suoi interessi professionali e personali ci sono le lingue e le culture straniere, i dialetti emiliani e i disturbi dell’apprendimento. 
	A 22 anni conosce Antonio Gallenga, personaggio dal quale continua, e probabilmente continuerà in futuro, ad essere profondamente affascinato.</ChiSono>
	<ChiSono imgSrc="cate.png" nome="Caterina De Nisco">llustratrice, ha studiato storia dell'arte e disegno tra Parma, Siena, Tarragona, Macerata, Aguascalientes, San Pietroburgo, Carrara. Si laurea con una tesi sui libri tattili e consegue un Master in Illustrazione per l’Editoria, illustrando il racconto di Lev Tolstoj “La bambina dei funghi” pubblicato a Mosca da Innopraktika and Russian Season School nel 2021. 
	Nel 2012 fonda Ludoteca, progetto editoriale indipendente e nel 2019 CollArte, associazione di giovani artisti. Conduce da tre anni laboratori espressivi con utenti psichiatrici, detenuti, minori.</ChiSono>
	<ChiSono imgSrc="elisetta.png" nome="Elisa Bortolin">Dopo una laurea in scienze dell’educazione e una decina di anni dedicati alla professione teatrale, decide di trasferirsi a Berceto, in Appennino. 
	Qui avvia numerose collaborazioni con le amministrazioni locali, per la creazione e lo sviluppo di progetti sociali e culturali.
	Nel 2020 fonda l’Associazione Appennino Ritrovato, di cui è presidente, il cui obiettivo primario è la valorizzazione del patrimonio materiale e immateriale di queste aree.</ChiSono>
	<ChiSono imgSrc="fabio.jpg" nome="Fabio Ziliani">Informatico per passione fin da bambino. In vent'anni di carriera ne ha viste di cotte e di crude e fatte di ogni. Sempre pronto all'avventura, soprattutto per dare vita a nuovi progetti, specie in ambito culturale.</ChiSono>
</ChiSiamo>
<h1>Seguici anche su facebook.</h1>
<FbPage />

	</PaginaTesto>
}